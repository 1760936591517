import request from '@/utils/request'

const url = '/webchat'


export function paymentOrderAsync(openid: string, orderId: string | Array<string | null>, orderType: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    request({
      url: `${url}/payment?openid=${openid}&orderId=${orderId}&orderType=${orderType}`,
      method: 'post'
    }).then((res) => resolve(res.data)).catch((err) => reject(err));
  })
}

export function queryOrderAsync(orderCode: string, orderType: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    request({
      url: `${url}/query`,
      method: 'get',
      params: {
        orderCode,
        orderType
      }
    }).then((res) => resolve(res.data)).catch((err) => reject(err));
  })
}
